import Footer from "./Footer";
import { Link } from "react-router-dom";
import Review from "./Reviews";

export default function LandingPage() {
  return (
    <div className="bg-black min-h-screen">
              <div className="md:hidden flex flex-col justify-center items-center">
          <img
            className="w-40 mb-4"
            src={require("./pictures/zeronimo_logo.png")}
            alt="Zeronimo Logo"
          />
          <p className="text-white text-center text-cursive didact-gothic-regular text-xl mx-5">
          In partnership with Ocean Beach Brands, Heribert Bayer proudly announces the US debut of ZERONIMO wine, the world’s first-ever dealcoholized 98 point rated wine.
          </p>
          <br />
          <Link to="http://zeronimowine.com">
            <p className="text-white hover:text-blue-200 text-center pb-8 didact-gothic-regular">
              Learn more about Zeronimo{" "}
            </p>
          </Link>
          <Link to="http://oceanbeachbrands.myshopify.com">
            <button className="animate-pulse bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              Order now!
            </button>
          </Link>
          <button className="underline text-white hover:text-blue-200 text-center pt-8 didact-gothic-regular"
          onClick={() => window.location = "mailto:hi@oceanbeachbrands.com"}>
            Inquire about Wholesale here
            </button>
        </div>
      <div className="grid grid-cols-2 md:grid-cols-3 px-10 md:max-w-5xl mx-auto">
        <img
          className="w-60"
          src={require("./pictures/Big Zeronimo Red.png")}
          alt="Big Zeronimo Red"
        />
        <img
          className="w-60"
          src={require("./pictures/Euro Sparkling Zeronimo Label.png")}
          alt="Euro Sparkling Zeronimo Label"
        />
        <div className="hidden md:flex md:flex-col md:justify-center md:items-center">
          <img
            className="w-40 mb-4"
            src={require("./pictures/zeronimo_logo.png")}
            alt="Zeronimo Logo"
          />
          <p className="text-white text-center text-cursive didact-gothic-regular text-xl">
          In partnership with Ocean Beach Brands, Austrian winemaker Heribert Bayer proudly announces the US debut of ZERONIMO wine, the first-ever 98 point rated wine to be dealcoholized.
          </p>
          <br />
          <Link to="http://zeronimowine.com">
            <p className="text-white hover:text-blue-200 text-center pb-10 didact-gothic-regular">
              Learn more about Zeronimo{" "}
            </p>
          </Link>
          <Link to="http://oceanbeachbrands.myshopify.com">
            <button className="animate-pulse bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              Order now!
            </button>
          </Link>
          <button className="underline text-white hover:text-blue-200 text-center pt-10 didact-gothic-regular"
          onClick={() => window.location = "mailto:hi@oceanbeachbrands.com"}>
            Inquire about Wholesale here
            </button>
        </div>
      </div>
      <Review/>
      <Footer />
    </div>
  );
}
